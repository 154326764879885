<template>
  <BasePage container-style="max-width: 1200px" header-style="max-width: 1200px">
    <template #page-header:subtitle>
      <BackButton class="mr-4" ref="backButton" :fallback-to="{ name: 'guard' }" />
      {{ `${route.meta.title}` }}
    </template>

    <template #page-header:bottom>
      <ErrorAlert
        v-if="error != null"
        dense
        :error="error"
        @clearErrors="clearErrors"
        class="mb-0 mt-4 rounded"
      />
    </template>

    <v-progress-linear color="primary" indeterminate v-if="isLoading" />

    <template v-else-if="report">
      <ReportStateCard
        v-if="report.state && reportVisualState"
        :color="reportVisualState.color"
        :display="report.state.display!"
        :icon="reportVisualState.icon!"
      >
        <template #actions>
          <GuardReportDraftStateActions
            :can-submit="report.canSubmit()"
            v-if="reportVisualState.state == ReportStateEnum.Draft"
            :loading="isLoading || isSubmitGuardReportPending || isDownloadGuardReportPdfPending"
            @submitted="submit()"
            @download-report-pressed="downloadReportPdf()"
            @edit-report-pressed="openEditReportDialog()"
            @delete-report-pressed="openDeleteReportDialog()"
            @report-coordinates-pressed="openReportCoordinates()"
          />
          <GuardReportSubmittedStateActions
            :can-approve="report.canApprove()"
            v-if="reportVisualState.state == ReportStateEnum.Submitted"
            :loading="isLoading || isApproveGuardReportPending || isDownloadGuardReportPdfPending"
            @approved="approve()"
            @download-report-pressed="downloadReportPdf()"
            @edit-report-pressed="openEditReportDialog()"
            @delete-report-pressed="openDeleteReportDialog()"
            @report-coordinates-pressed="openReportCoordinates()"
          />
          <GuardReportApprovedStateActions
            v-if="reportVisualState.state == ReportStateEnum.Approved"
            :loading="isLoading || isDownloadGuardReportPdfPending"
            @download-report-pressed="downloadReportPdf()"
            @report-coordinates-pressed="openReportCoordinates()"
          />
        </template>
      </ReportStateCard>

      <ReportDetailView class="mt-5" report-title="Guard Activity Report" :report="report">
        <template #append-report-info>
          <tr class="report-table__row">
            <td class="report-table__cell pr-3" colspan="1">
              <strong class="wv-text--strong">Start Time:</strong>
            </td>
            <td class="report-table__cell-info" colspan="1">
              <span class="wv-text wv-text--body"
                >{{ format(report.shift.start, 'MMMM dd, yyyy - HH:mm') }}
              </span>
            </td>
          </tr>
          <tr class="report-table__row">
            <td class="report-table__cell pr-3" colspan="1">
              <strong class="wv-text--strong">End Time:</strong>
            </td>
            <td class="report-table__cell-info" colspan="1">
              <span class="wv-text wv-text--body"
                >{{ format(report.shift.end, 'MMMM dd, yyyy - HH:mm') }}
              </span>
            </td>
          </tr>
          <tr class="report-table__row">
            <td class="report-table__cell pr-3" colspan="1">
              <strong class="wv-text--strong">Guard:</strong>
            </td>
            <td class="report-table__cell-info" colspan="1">
              <span class="wv-text wv-text--body">{{ report.owner }} </span>
            </td>
          </tr>
        </template>

        <template #memo-content>
          <section class="mx-16 my-3" v-if="report.memo">
            <div class="d-flex flex-grow-1 justify-space-between align-center pt-1">
              <strong class="text-high-emphasis"> Memo </strong>
            </div>
            <div class="text-medium-emphasis mb-10" :style="{ whiteSpace: 'pre-line' }">
              {{ report.memo }}
            </div>
          </section>
        </template>

        <template #content>
          <GuardReportDetailActivityCard :report="report" />
        </template>
      </ReportDetailView>
    </template>

    <GuardReportDetailFormDialog
      v-model:dialog="guardEditDialog"
      :guard-report="report"
      :is-edit="true"
      @saved-report="updateReportCache()"
    />

    <GuardReportCoordinatesDialog
      v-if="report"
      v-model:dialog="reportCoordinatesDialog"
      :guard-report="report"
    />

    <ConfirmationDialog
      v-model="guardDeleteDialog"
      v-model:error="deleteError"
      title="Delete CCTV Report"
    >
      <template v-slot:message>
        Are you sure you want to delete CCTV Report
        <span class="text-medium-high-emphasis font-weight-bold">#{{ report!.report_id }}</span
        >?
        <br />
        <div class="pt-2">This action cannot be undone.</div>
      </template>

      <template v-slot:actions>
        <v-spacer></v-spacer>

        <v-btn
          color="error"
          variant="flat"
          class="text-capitalize"
          :loading="deleteInProgress"
          @click.stop="removeGuardReport(report!)"
        >
          Delete
        </v-btn>
      </template>
    </ConfirmationDialog>
  </BasePage>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { format } from 'date-fns'
import { storeToRefs } from 'pinia'

import { useDeleteGuardReport, useFetchGuardReport } from '@/composables/guard-report'
import { useReportStore } from '@/stores'
import { useMutation, useQueryClient } from '@tanstack/vue-query'

import guardReportService from '@/services/guard_report/guard-report'

import { ReportStateEnum, type IGuardReport } from '@/models/report'

import BasePage from '@/components/base/BasePage.vue'
import BackButton from '@/components/common/BackButton.vue'
import ConfirmationDialog from '@/components/common/ConfirmationDialog.vue'
import ErrorAlert from '@/components/common/ErrorAlert.vue'
import GuardReportApprovedStateActions from '@/components/reports/guard/GuardReportApprovedStateActions.vue'
import GuardReportDraftStateActions from '@/components/reports/guard/GuardReportDraftStateActions.vue'
import GuardReportSubmittedStateActions from '@/components/reports/guard/GuardReportSubmittedStateActions.vue'
import GuardReportDetailFormDialog from '@/components/reports/guard/GuardReportDetailFormDialog.vue'
import GuardReportCoordinatesDialog from '@/components/reports/guard/GuardReportCoordinatesDialog.vue'
import ReportDetailView from '@/components/reports/common/ReportDetailView.vue'
import ReportStateCard from '@/components/reports/common/ReportStateCard.vue'
import GuardReportDetailActivityCard from '@/components/reports/guard/GuardReportDetailActivityCard.vue'
import type { ISystemError } from '@/models/error'
import { createPdf } from '@/utils/helpers'

// CORE
const queryClient = useQueryClient()
const route = useRoute()
const router = useRouter()

// Managing multiple errors into one property
const error = ref<ISystemError | null>(null)
const guardDeleteDialog = ref(false)
const guardEditDialog = ref(false)
const reportCoordinatesDialog = ref(false)

const reportStore = useReportStore()
const { getReportVisualByState } = storeToRefs(reportStore)

const reportVisualState = computed(
  () => report.value && getReportVisualByState.value(report.value.state!.value!)
)

const {
  report,
  isLoading,
  error: fetchError,
  queryKey
} = useFetchGuardReport(Number(route.params.id))
// update error to crud action error display
watch(fetchError, (value) => {
  console.log(value)

  error.value = value
})

function openDeleteReportDialog() {
  guardDeleteDialog.value = true
}
function openEditReportDialog() {
  guardEditDialog.value = true
}
function openReportCoordinates() {
  reportCoordinatesDialog.value = true
}

const { isPending: isSubmitGuardReportPending, mutate: submitGuardReport } = useMutation({
  mutationKey: ['submit-guard-report'],
  mutationFn: (reportId: number) => guardReportService.submitGuardReport(reportId)
})
// STATE TRANSITIONS
function submit() {
  clearErrors()
  submitGuardReport(report.value!.id!, {
    onSuccess: () => {
      updateReportCache()
    },
    onError(submitError) {
      error.value = submitError
    }
  })
}

const { isPending: isApproveGuardReportPending, mutate: approveGuardReport } = useMutation({
  mutationKey: ['approve-guard-report'],
  mutationFn: (reportId: number) => guardReportService.approveGuardReport(reportId)
})
function approve() {
  clearErrors()
  approveGuardReport(report.value!.id!, {
    onSuccess: () => {
      updateReportCache()
    },
    onError(approveError) {
      error.value = approveError
    }
  })
}

const { isPending: isDownloadGuardReportPdfPending, mutate: downloadGuardReportPDF } = useMutation({
  mutationKey: ['approve-guard-report'],
  mutationFn: (reportId: number) => guardReportService.exportGuardReportPdf(reportId)
})

function downloadReportPdf() {
  clearErrors()
  downloadGuardReportPDF(report.value!.id!, {
    onSuccess: (data) => {
      updateReportCache()
      createPdf(data, report.value!.report_id!)
    },
    onError(downloadError) {
      error.value = downloadError
    }
  })
}

function clearErrors() {
  error.value = null
}

const backButton = ref<typeof BackButton>()

const { deleteInProgress, deleteError, deleteGuardReport } = useDeleteGuardReport()

function updateReportCache() {
  // TODO - LH - 2024-02-20 - Should find a better way to invalidate both queries
  // invalidate current incident report cache to update this cache
  queryClient.invalidateQueries({ queryKey: queryKey.value })
  // invalidate list cache regarding incident
  queryClient.invalidateQueries({ queryKey: ref(['guard-reports']) })
}

function removeGuardReport(report: IGuardReport) {
  clearErrors()
  deleteGuardReport(report.id!, {
    onSuccess: () => {
      guardDeleteDialog.value = false
      queryClient.invalidateQueries({ queryKey: ref(['guard-reports']) })
      router.push(backButton.value?.backPath)
    }
  })
}
</script>
