<template>
  <div class="post-order-instruction-observation-submission">
    <v-sheet class="d-flex mb-1 align-center justify-space-between rounded" color="#c9c9c960">
      <div class="ms-3 py-1" style="font-size: large; font-weight: 500">
        {{ instruction.submission.form }}
      </div>
      <div class="me-3 py-1" style="font-size: large; font-weight: 500">
        {{ format(instruction.submission.created!, 'MMMM dd, yyyy - HH:mm') }}
      </div>
    </v-sheet>

    <div>
      <v-row
        class="ma-0"
        v-for="(section, sectionIndex) in instruction.section_submissions"
        :key="sectionIndex"
      >
        <v-col
          order-md="1"
          order="2"
          cols="12"
          :md="showHalfWidth(section) ? 5 : 12"
          class="d-flex flex-column"
          style="position: relative"
        >
          <div>
            <b
              ><div class="d-flex flex-row align-center">
                <div class="flex-1-0 flex-grow-1">Checkpoint:</div>

                <div class="flex-0-1 ml-auto mt-n2 mr-n2" v-if="!showHalfWidth(section)">
                  <v-chip
                    label
                    size="small"
                    :prepend-icon="getSectionSubmissionVisualByState(section.state)?.icon"
                    :color="getSectionSubmissionVisualByState(section.state)?.color"
                    class="text-caption font-weight-medium"
                  >
                    {{ getSectionSubmissionVisualByState(section.state)?.display }}
                  </v-chip>
                </div>
              </div>
            </b>
            <div class="text-report-grey">{{ section.section.toString() }}</div>
          </div>

          <div class="pt-2">
            <b>Reported By:</b>
            <div class="text-report-grey">{{ reporter }}</div>
          </div>

          <template v-if="section.section_type == PostOrderInstructionSectionTypeEnum.VehicleLog">
            <PostOrderInstructionObservationSubmissionVehicleLog
              v-if="getVehicleLogIdFromSectionSubmission(section)"
              :vehicle-log-id="getVehicleLogIdFromSectionSubmission(section) as number"
            />
          </template>

          <div class="pt-2" v-else>
            <div>
              <div class="font-weight-bold">Notes:</div>
              <div
                class="text-report-grey"
                :class="{ 'pb-4': !generateObservationTextFromSectionSubmission(section) }"
                :style="{ whiteSpace: 'pre-line' }"
              >
                {{
                  generateObservationTextFromSectionSubmission(section)
                    ? generateObservationTextFromSectionSubmission(section)
                    : '-'
                }}
              </div>
            </div>
            <div v-for="(field, fieldIndex) in section.field_submissions" :key="fieldIndex">
              <div
                v-if="
                  field.field_submission.field.type != FormFieldTypeEnum.FileField &&
                  field.field_submission.field.type != FormFieldTypeEnum.ComboBox
                "
                class="mb-2"
              >
                <div class="font-weight-medium">{{ field.field_submission.field.label }}</div>
                <div class="text-report-grey" :style="{ whiteSpace: 'pre-line' }">
                  {{ field.field_submission.value ? field.field_submission.value : '-' }}
                </div>
              </div>
            </div>
          </div>

          <div
            class="pt-auto mt-auto"
            v-if="
              section.state != PostOrderInstructionsSectionSubmissionStateEnum.NotApplicable &&
              !section.internal &&
              section.section_type != PostOrderInstructionSectionTypeEnum.VehicleLog
            "
          >
            <v-btn
              :color="generateReportIncidentStatusVisual(section)?.color"
              height="35"
              block
              class="text-white text-none mt-4"
              variant="flat"
              readonly
            >
              <div class="font-weight-medium pr-1" style="font-size: large">
                {{ generateReportIncidentStatusVisual(section)?.display }}
              </div>
            </v-btn>

            <v-tooltip>
              <template #activator="{ props }">
                <v-btn
                  v-if="
                    generateReportIncidentStatusVisual(section)?.state ==
                      ReportActivityStatusEnum.SecurityLevel3 &&
                    generateIncidentReportIdFromSectionSubmission(section)
                  "
                  v-bind="props"
                  density="compact"
                  flat
                  variant="tonal"
                  class="text-capitalize d-flex mt-4"
                  block
                  :color="generateReportIncidentStatusVisual(section)?.color"
                  @click="
                    openIncidentReportDialog(
                      generateIncidentReportIdFromSectionSubmission(section) as number
                    )
                  "
                >
                  <v-icon left class="mr-1">mdi-clipboard-alert </v-icon
                  >{{ generateIncidentReportReportIdFromSectionSubmission(section) }}
                </v-btn>
              </template>
              Navigate to this incident report here
            </v-tooltip>
          </div>
        </v-col>

        <v-col
          order-md="2"
          order="1"
          cols="12"
          md="7"
          class="px-0 pb-0 pt-1"
          v-if="section.state != PostOrderInstructionsSectionSubmissionStateEnum.NotApplicable"
        >
          <div class="mt-n2 mb-2" style="justify-self: end" v-if="showHalfWidth(section)">
            <v-chip
              label
              size="small"
              :prepend-icon="getSectionSubmissionVisualByState(section.state)?.icon"
              :color="getSectionSubmissionVisualByState(section.state)?.color"
              class="text-caption font-weight-medium"
            >
              {{ getSectionSubmissionVisualByState(section.state)?.display }}
            </v-chip>
          </div>
          <div>
            <div
              class="overflow-y-auto d-flex flex-column"
              v-if="section.section_type == PostOrderInstructionSectionTypeEnum.VehicleLog"
            >
              <VehicleLogAttachmentsImageViewerList
                v-if="getVehicleLogIdFromSectionSubmission(section)"
                :vehicle-log-id="getVehicleLogIdFromSectionSubmission(section) as number"
              />
              <v-alert v-else width="100%" type="error" title="Invalid vehicle log" />
            </div>
            <ImageViewer
              v-else-if="generateObservationFileFromSectionSubmission(section)"
              width="100%"
              :src="generateObservationFileFromSectionSubmission(section)"
              alt="Observation Image"
            />
          </div>
        </v-col>

        <v-divider class="my-8" v-if="sectionIndex != 0"></v-divider>
      </v-row>

      <v-row v-if="isObservationLoading">
        <v-col>
          <v-progress-circular indeterminate />
        </v-col>
      </v-row>

      <ErrorAlert
        v-if="observationError != null"
        :error="observationError"
        class="mb-0 mb-4 rounded"
      />

      <v-row
        class="ma-0"
        v-for="(observation, observationIndex) in observationsSubmissions"
        :key="observationIndex"
      >
        <v-col
          order-md="1"
          order="2"
          cols="12"
          md="5"
          class="d-flex flex-column pb-0"
          style="position: relative"
        >
          <div>
            <b>Checkpoint:</b>
            <div class="text-report-grey">{{ observation.location }}</div>
          </div>

          <div class="pt-2">
            <b>Reported By:</b>
            <div class="text-report-grey">{{ reporter }}</div>
          </div>

          <div class="pt-2">
            <b class="mr-1">Notes:</b>
            <div class="text-report-grey" :style="{ whiteSpace: 'pre-line' }">
              {{ observation.text }}
            </div>
          </div>

          <div class="pt-auto mt-auto">
            <v-btn
              :color="reportIncidentStatusVisual(observation.incident_level)?.color"
              height="35"
              block
              class="text-white text-none mt-4"
              variant="flat"
              readonly
            >
              <div class="font-weight-medium pr-1" style="font-size: large">
                {{ reportIncidentStatusVisual(observation.incident_level)?.display }}
              </div>
            </v-btn>
          </div>

          <div class="pt-4">
            <v-row dense>
              <v-col
                cols="12"
                md="auto"
                class="flex-grow-1"
                v-if="
                  observation.incident_report &&
                  currentUser?.hasPermission('incident_reports.view_incidentreport')
                "
              >
                <v-tooltip>
                  <template #activator="{ props }">
                    <v-btn
                      v-bind="props"
                      density="compact"
                      flat
                      variant="tonal"
                      class="text-capitalize d-flex"
                      block
                      :color="reportIncidentStatusVisual(observation.incident_level)?.color"
                      @click="openIncidentReportDialog(observation.incident_report)"
                    >
                      <v-icon left class="mr-1">mdi-clipboard-alert </v-icon>Incident-{{
                        observation.incident_report.toString().padStart(4, '0000')
                      }}</v-btn
                    >
                  </template>
                  Navigate to this incident report here
                </v-tooltip>
              </v-col>
            </v-row>
          </div>
        </v-col>
        <v-col order-md="2" order="1" cols="12" md="7" class="px-0 pb-0 pt-1">
          <div v-if="observation.file">
            <ImageViewer width="100%" :src="observation.file" />
          </div>
        </v-col>

        <v-divider class="my-8"></v-divider>
      </v-row>
    </div>
  </div>

  <IncidentReportDetailDialog
    v-if="selectedIncidentReportId"
    v-model="incidentReportDetailDialog"
    :report-id="selectedIncidentReportId"
  />
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { format } from 'date-fns'
import { storeToRefs } from 'pinia'

import {
  PostOrderInstructionSectionTypeEnum,
  type IPostOrderInstructionSectionSubmission,
  type IPostOrderInstructionSubmission
} from '@/models/post-order'
import { PostOrderInstructionsSectionSubmissionStateEnum } from '@/models/post-order'
import { FormFieldTypeEnum } from '@/models/form'
import { ReportActivityStatusEnum } from '@/models/report'

import { useFetchPostOrderInstructionsSubmissionObservations } from '@/composables/post-order'
import { useAuthStore, useReportStore } from '@/stores'

import ErrorAlert from '../common/ErrorAlert.vue'
import ImageViewer from '../common/ImageViewer.vue'
import VehicleLogAttachmentsImageViewerList from '@/components/fleet-management/vehicles-logs/VehicleLogAttachmentsImageViewerList.vue'
import PostOrderInstructionObservationSubmissionVehicleLog from './PostOrderInstructionObservationSubmissionVehicleLog.vue'
import IncidentReportDetailDialog from '@/components/reports/incident/IncidentReportDetailDialog.vue'

interface Props {
  postOrderId: number
  postOrderSubmissionId: number
  instruction: IPostOrderInstructionSubmission
  reporter: string
}

const props = defineProps<Props>()

const authStore = useAuthStore()
const { getUser: currentUser } = storeToRefs(authStore)

const reportStore = useReportStore()
const { getReportIncidentStatusVisualByState } = storeToRefs(reportStore)

const {
  observationsSubmissions,
  isLoading: isObservationLoading,
  error: observationError
} = useFetchPostOrderInstructionsSubmissionObservations(
  props.postOrderId,
  props.postOrderSubmissionId,
  props.instruction.id!
)

function showHalfWidth(section: IPostOrderInstructionSectionSubmission) {
  return (
    generateObservationFileFromSectionSubmission(section) ||
    (section.section_type == PostOrderInstructionSectionTypeEnum.VehicleLog &&
      section.state != PostOrderInstructionsSectionSubmissionStateEnum.NotApplicable)
  )
}

function generateIncidentReportReportIdFromSectionSubmission(
  sectionSubmission: IPostOrderInstructionSectionSubmission
): string {
  return sectionSubmission.field_submissions.reduce((reportId, section) => {
    section.option_submissions.forEach((optionSubmission) => {
      if (optionSubmission.incident_report) {
        reportId = optionSubmission.incident_report.report_id!
      }
    })
    return reportId
  }, '')
}

function generateIncidentReportIdFromSectionSubmission(
  sectionSubmission: IPostOrderInstructionSectionSubmission
): number | null {
  return (
    sectionSubmission.field_submissions.reduce((incidentId, section) => {
      section.option_submissions.forEach((optionSubmission) => {
        if (optionSubmission.incident_report) {
          incidentId = optionSubmission.incident_report.id!
        }
      })
      return incidentId
    }, 0) ?? null
  )
}

function reportIncidentStatusVisual(observationIncidentLevel: ReportActivityStatusEnum) {
  return getReportIncidentStatusVisualByState.value(observationIncidentLevel) ?? null
}

function generateReportIncidentStatusVisual(
  sectionSubmission: IPostOrderInstructionSectionSubmission
) {
  return getReportIncidentStatusVisualByState.value(
    generateObservationIncidentLevelFromSectionSubmission(sectionSubmission)
  )
}

/**
 * Generates the highest incident level from a given section submission.
 *
 * Iterates through the field submissions of a section submission,
 * and for each field submission, it iterates through the option submissions.
 * If an option submission contains an incident report with an incident level
 * higher than the current highest incident level, it updates the current highest
 * incident level.
 *
 * @param {IPostOrderInstructionSectionSubmission} sectionSubmission - The section submission containing field submissions and option submissions.
 * @returns {ReportActivityStatusEnum} - The highest incident level found in the section submission.
 */
function generateObservationIncidentLevelFromSectionSubmission(
  sectionSubmission: IPostOrderInstructionSectionSubmission
): ReportActivityStatusEnum {
  return sectionSubmission.field_submissions.reduce((currentIncidentLevel, section) => {
    section.option_submissions.forEach((optionSubmission) => {
      currentIncidentLevel = optionSubmission.incident_level
    })
    return currentIncidentLevel
  }, ReportActivityStatusEnum.SecurityLevel1)
}

function generateObservationFileFromSectionSubmission(
  sectionSubmission: IPostOrderInstructionSectionSubmission
) {
  for (const section of sectionSubmission.field_submissions) {
    if (
      section.field_submission.field.type == FormFieldTypeEnum.FileField &&
      section.file_submission
    ) {
      return section.file_submission.file
    }
  }
  return ''
}

function generateObservationTextFromSectionSubmission(
  sectionSubmission: IPostOrderInstructionSectionSubmission
) {
  return sectionSubmission.field_submissions.reduce((observationText, section) => {
    if (section.field_submission.value === '') {
      section.option_submissions.forEach((optionSubmission) => {
        if (optionSubmission.observation_text) {
          observationText += `${optionSubmission.observation_text}\n\n`
        }
      })
    }
    return observationText
  }, '')
}

function getVehicleLogIdFromSectionSubmission(
  sectionSubmission: IPostOrderInstructionSectionSubmission
): number | null {
  const fieldSubmission = sectionSubmission.field_submissions.find((fieldSubmission) => {
    const vehicleLogId = JSON.parse(fieldSubmission.field_submission.value)['logId']
    return vehicleLogId
  })
  return fieldSubmission ? JSON.parse(fieldSubmission.field_submission.value)['logId'] : null
}

function getSectionSubmissionVisualByState(
  sectionSubmissionState: PostOrderInstructionsSectionSubmissionStateEnum
) {
  return [
    {
      state: PostOrderInstructionsSectionSubmissionStateEnum.Todo,
      icon: 'mdi-checkbox-intermediate-variant',
      color: 'rgba(var(--v-theme-submitted))',
      display: 'In Progress'
    },
    {
      state: PostOrderInstructionsSectionSubmissionStateEnum.Completed,
      icon: 'mdi-checkbox-marked',
      color: 'rgba(var(--v-theme-approved))',
      display: 'Completed'
    },
    {
      state: PostOrderInstructionsSectionSubmissionStateEnum.NotApplicable,
      icon: 'mdi-checkbox-blank-outline',
      color: 'rgba(var(--v-theme-reject))',
      display: 'Skipped'
    }
  ].find((stateVisual) => stateVisual.state == sectionSubmissionState)
}

const incidentReportDetailDialog = ref(false)
const selectedIncidentReportId = ref<number | null>(null)
function openIncidentReportDialog(incidentReportId: number) {
  incidentReportDetailDialog.value = true

  selectedIncidentReportId.value = incidentReportId
}
</script>

<style scoped>
.post-order-instruction-observation-submission {
  margin-block-end: 12px;
}
</style>
