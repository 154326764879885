<template>
  <v-dialog v-model="dialog" v-bind="$attrs" persistent max-width="1200">
    <AlFormCard
      title="Guard Report Coordinates"
      width="100%"
      :show-actions="false"
      :loading="isLoading"
    >
      <template #error>
        <ErrorAlert v-if="error != null" :error="error" @clearErrors="clearErrors()" class="mb-2">
        </ErrorAlert>
      </template>

      <template #appendAction>
        <v-btn
          variant="tonal"
          class="rounded"
          size="32"
          @click="closeDialog()"
          icon
          color="default"
          density="comfortable"
        >
          <v-icon size="18" icon="mdi-close" />
        </v-btn>
      </template>

      <div>
        <v-card flat height="calc(100vh - 228px)">
          <GeoAuditLogMap
            style="height: 100%; width: 100%"
            :geo-audit-logs="logs ?? []"
            :loading="isLoading"
          />
        </v-card>
      </div>
    </AlFormCard>
  </v-dialog>
</template>

<script setup lang="ts">
import { computed, reactive, ref } from 'vue'

import { type IGuardReport } from '@/models/report'
import { useFetchGeoAuditLogs } from '@/composables/geo-audit-log'
import type { IGeoAuditLogFilterParam } from '@/services'
import type { ISystemError } from '@/models/error'

import AlFormCard from '@/components/common/AlFormCard.vue'
import ErrorAlert from '@/components/common/ErrorAlert.vue'
import GeoAuditLogMap from '@/components/geo-audit-log/GeoAuditLogMap.vue'

const dialog = defineModel<boolean>('dialog', { required: true })

interface Props {
  guardReport: IGuardReport
}
const props = defineProps<Props>()

const currentGuardReport = computed(() => props.guardReport)

const geoAuditLogFilters = reactive<IGeoAuditLogFilterParam>({
  search: '',
  page: 1,
  user:
    currentGuardReport.value.shift.guards!.length! > 0
      ? currentGuardReport.value.shift.guards![0].id
      : undefined,
  timestamp_after: currentGuardReport.value.shift.start!.toISOString(),
  timestamp_before: currentGuardReport.value.shift.end!.toISOString()
})

const enableFetch = computed(() => currentGuardReport.value && dialog.value)
const { logs, isLoading, error: fetchError } = useFetchGeoAuditLogs(geoAuditLogFilters, enableFetch)

// Fix for receiving a readonly error when using fetchError directly
const localError = ref<ISystemError | null>(null)
const error = computed<ISystemError | null>({
  get: () => localError.value || fetchError.value,
  set: (value) => {
    localError.value = value
  }
})

function clearErrors() {
  localError.value = null
}

function closeDialog() {
  clearErrors()
  dialog.value = false
}
</script>

<style lang="scss"></style>
