<template>
  <NoDataAvailablePlaceholder
    v-if="geoAuditLogs.length == 0"
    header="No geo audit log available"
    header-class="font-weight-medium text-h5"
  >
    <template #prepend>
      <v-card
        variant="flat"
        class="mask-bottom-card mb-3"
        color="transparent"
        width="180"
        height="120"
      >
        <v-list
          style="color: rgba(var(--v-theme-on-surface), var(--v-disabled-opacity))"
          variant="outlined"
          density="compact"
          lines="two"
          class="pt-0"
        >
          <v-list-item class="rounded-lg-xl mb-3 px-3">
            <v-skeleton-loader class="me-n3 ms-n4 my-n2" type="text, text" boilerplate />
          </v-list-item>
          <v-list-item class="rounded-lg-xl mb-3 px-3">
            <v-skeleton-loader class="me-n3 ms-n4 my-n2" type="text, text" boilerplate />
          </v-list-item>
        </v-list>
      </v-card>
    </template>

    <template #description>
      <p class="mt-0 mb-6 text-caption text-medium-emphasis">
        <span> No Geo Audit logs available.</span>
      </p>
    </template>
  </NoDataAvailablePlaceholder>
  <MapboxMap
    v-else
    :access-token="accessToken"
    map-style="mapbox://styles/mapbox/streets-v12"
    :center="mapCenter"
    :zoom="17"
  >
    <MapboxPopup
      v-if="isOpen"
      :key="position.join('-')"
      :lng-lat="position"
      anchor="bottom"
      @mb-close="() => (isOpen = false)"
    >
      <h3>{{ content.user }}</h3>
      <div style="height: 76px; overflow: auto">
        <template v-for="(log, i) in content.logs" :key="i">
          <p>
            {{ log.activity ? `${log.activity} - ` : '' }}
            {{ format(log.timestamp, 'MMMM d, yyyy h:mma') }}
          </p>
        </template>
      </div>
    </MapboxPopup>
    <MapboxImage id="logo" :src="miniCompanyImage">
      <MapboxCluster
        :data="mapGeoJsonData"
        @mb-feature-click="openPopup"
        unclustered-point-layer-type="symbol"
        :unclustered-point-layout="{
          'icon-image': 'logo',
          'icon-size': 0.1
        }"
        :unclustered-point-paint="null"
      />
    </MapboxImage>
  </MapboxMap>
</template>

<script lang="ts" setup>
import { nextTick, ref, watch } from 'vue'
import { format } from 'date-fns'

import type { IGeoAuditLog } from '@/models/geo-audit-log'
import { GeoJson, type IGeoJsonFeature } from '@/models/geo-json'
import miniCompanyImage from '@/assets/logo.png'

import { MapboxMap, MapboxCluster, MapboxPopup, MapboxImage } from '@studiometa/vue-mapbox-gl'
import NoDataAvailablePlaceholder from '@/components/common/NoDataAvailablePlaceholder.vue'

interface Props {
  geoAuditLogs: IGeoAuditLog[]
  loading: boolean
}

const props = defineProps<Props>()
const accessToken = import.meta.env.VITE_APP_MAP_BOX_TOKEN

const isOpen = ref(false)
const position = ref([0, 0])
const content = ref()

async function openPopup({ geometry, properties }: IGeoJsonFeature) {
  //retrieve geojson data then filter and map audit log data to show in popup dialog
  await nextTick()
  position.value = [...geometry.coordinates]
  isOpen.value = true

  //gets all timestamps for this specific location
  const logs = props.geoAuditLogs.filter(
    (log) =>
      log.longitude!.toFixed(2) == geometry.coordinates[0].toFixed(2) &&
      log.latitude!.toFixed(2) == geometry.coordinates[1].toFixed(2)
  )

  //this content value is use to show popup dialog data
  content.value = { ...properties, logs: logs }
}

const mapGeoJsonData = ref()
const mapCenter = ref()

watch(
  () => props.geoAuditLogs,
  (value: IGeoAuditLog[]) => {
    mapGeoJsonData.value = new GeoJson({
      type: 'FeatureCollection',
      features: value.map((log) => {
        return {
          type: 'Feature',
          geometry: { type: 'Point', coordinates: [log.longitude, log.latitude] },
          properties: log
        } as IGeoJsonFeature
      })
    })

    mapCenter.value =
      mapGeoJsonData.value.features.length == 0
        ? [-96, 37.8]
        : mapGeoJsonData.value.features[0].geometry.coordinates
  },
  {
    immediate: true,
    deep: true
  }
)
</script>

<style lang="scss">
.mapboxgl-popup-close-button {
  font-size: large;
  position: absolute;
  right: 0;
  top: 0;
  border: 0;
  width: 20px;
  height: 20px;
  border-radius: 0 3px 0 0;
  cursor: pointer;
  background-color: transparent;
}
</style>
