<template>
  <v-progress-circular indeterminate v-if="isPending" />

  <ErrorAlert v-if="error != null" :error="error" class="mb-0 mb-4 rounded" />

  <div v-if="vehicleLog">
    <div class="pt-2">
      <b>Vehicle:</b>
      <div class="text-report-grey">
        {{ (vehicleLog.vehicle as IVehicle).year }}
        {{ ((vehicleLog.vehicle as IVehicle).model as IVehicleModel).make }}
        {{ ((vehicleLog.vehicle as IVehicle).model as IVehicleModel).name }}
      </div>
    </div>

    <div class="pt-2">
      <b>Mileage</b>
      <div class="text-report-grey">{{ vehicleLog.mileage }}</div>
    </div>

    <div class="pt-2">
      <b>Fuel Percentage</b>
      <div class="text-report-grey">{{ vehicleLog.fuel_percentage }}%</div>
    </div>

    <div class="pt-2">
      <b>Notes:</b>
      <div class="text-report-grey" :style="{ whiteSpace: 'pre-line' }">{{ vehicleLog.notes }}</div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import ErrorAlert from '@/components/common/ErrorAlert.vue'
import { useFetchVehicleLog } from '@/composables/vehicle-log'
import type { IVehicle, IVehicleModel } from '@/models/fleet-management'

interface Props {
  vehicleLogId: number
}
const props = defineProps<Props>()
const vehicleLogId = computed(() => props.vehicleLogId!)

const { log: vehicleLog, error, isPending } = useFetchVehicleLog(vehicleLogId)
</script>
