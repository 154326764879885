<template>
  <div class="al-page-container position-relative">
    <BasePageHeader
      v-if="!noHeader"
      :class="{ 'al-page-sticky-header': stickyHeader }"
      v-bind="props"
      :style="containerStyle"
    >
      <template #default>
        <slot name="page-header:title">{{ title }}</slot>
      </template>
      <template #subtitle>
        <slot name="page-header:subtitle">{{ subtitle }}</slot>
      </template>

      <template #actions>
        <slot name="page-header:actions"></slot>
      </template>
      <template #bottom>
        <slot name="page-header:bottom"></slot>
      </template>
    </BasePageHeader>

    <template v-else>
      <slot name="page:top"></slot>
    </template>
    <v-container
      class="al-page-content"
      :class="alPageContainerClasses"
      :fluid="fluid"
      tag="section"
      :style="containerStyle"
    >
      <slot name="default"></slot>
    </v-container>
  </div>
</template>

<script lang="ts" setup>
import { computed, type PropType, type StyleValue } from 'vue'
import BasePageHeader from './BasePageHeader.vue'

const props = defineProps({
  title: { type: String, default: () => '' },
  subtitle: { type: String, default: () => '' },
  truncateTitle: { type: Boolean, default: () => true },
  disableTitleStyles: { type: Boolean, default: () => false },
  noHeader: { type: Boolean, default: () => false },
  fluid: { type: Boolean, default: () => true },
  stickyHeader: { type: Boolean, default: () => false },
  noPadding: { type: Boolean, default: () => false },
  noMargin: { type: Boolean, default: () => false },
  overflowX: { type: Boolean, default: () => false },
  containerStyle: {
    type: [String, Boolean, Array, Object] as PropType<StyleValue | null>,
    default: () => null
  },
  headerStyle: {
    type: [String, Boolean, Array, Object] as PropType<StyleValue | null>,
    default: () => null
  }
})

const alPageContainerClasses = computed(() => {
  let containerClasses = 'py-2 px-4 px-sm-6 px-md-8 px-lg-10'
  if (props.noPadding) return 'pa-0'
  if (props.overflowX) containerClasses += ' overflow-x-auto'
  return containerClasses
})
</script>

<style lang="scss">
.al-page-sticky-header {
  position: sticky;
  z-index: 3;
}
.al-page-content {
  .v-data-table {
    .v-data-footer {
      justify-content: flex-end !important;
    }
  }
}
</style>
