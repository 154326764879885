<template>
  <IncidentReportDetailCard :title="`${route.meta.title}`" :reportId="Number(route.params.id)" />
</template>

<script setup lang="ts">
import { useRoute } from 'vue-router'

import IncidentReportDetailCard from '@/components/reports/incident/IncidentReportDetailCard.vue'

const route = useRoute()
</script>
