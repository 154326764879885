import { ref, toRef, type MaybeRef } from 'vue'

import { GeoAuditLog } from '@/models/geo-audit-log'

import geoAuditLogService from '@/services/geo_audit_log/geo-audit-log'

import { useQuery } from '@tanstack/vue-query'
import type { IGeoAuditLogFilterParam } from '@/services/geo_audit_log'

export function useFetchGeoAuditLogs(
  filter: MaybeRef<IGeoAuditLogFilterParam>,
  enable: MaybeRef<boolean> = true
) {
  const filterRef = toRef(filter)
  const queryKey = ref(['geo-audit-logs', filterRef])

  const count = ref<number>(0)

  const {
    isLoading,
    isError,
    error,
    data: logs
  } = useQuery({
    queryKey: queryKey,
    queryFn: () => geoAuditLogService.fetchGeoAuditLogs(filterRef.value!),
    select: (data) => {
      count.value = data.count
      return data.results.map((geoAuditLog) => new GeoAuditLog(geoAuditLog))
    },
    enabled: enable
  })

  return { queryKey, logs, count, error, isError, isLoading }
}

export function useFetchGeoAuditLog(logId: MaybeRef<number>) {
  const geoAuditLogIdRef = toRef(logId)

  const queryKey = ref(['geo-audit-log', geoAuditLogIdRef])

  const {
    data: log,
    isPending,
    error
  } = useQuery({
    queryKey: queryKey,
    queryFn: () => geoAuditLogService.fetchGeoAuditLog(geoAuditLogIdRef.value),
    select: (data) => new GeoAuditLog(data)
  })

  return { log, isPending, error, queryKey }
}
