import { AbstractModel } from '../base'
import type { IGeoAuditLog, IGeoAuditLogData } from './geo-audit-log.types'

export class GeoAuditLog extends AbstractModel implements IGeoAuditLog {
  readonly id: number
  readonly user: string
  readonly latitude: number
  readonly longitude: number
  readonly timestamp: Date
  readonly activity: string = ''

  constructor(data: IGeoAuditLogData) {
    super()

    // Validate data
    const requiredFields: (keyof IGeoAuditLogData)[] = [
      'id',
      'user',
      'latitude',
      'longitude',
      'timestamp'
    ]
    this.validate(data, requiredFields)

    this.id = data.id!
    this.user = data.user!
    this.longitude = data.longitude!
    this.latitude = data.latitude!
    this.timestamp = typeof data.timestamp === 'string' ? new Date(data.timestamp) : data.timestamp!

    if (data.activity) {
      this.activity = data.activity
    }
  }
}
